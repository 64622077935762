import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div className="footer">
      <div className="scroll-watcher"></div>
    </div>
  )
}

export default Footer